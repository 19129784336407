<script>
  import screen from '@/helpers/screen'

  /**
   * A form control that allows the user to select a quantity, with a considerably different UI between mobile (`<select>`) and desktop (`<input type="number">` with `+` and `-` buttons).
   */
  export default {
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      /** The current quantity. */
      value: {
        type: Number,
        default: 0,
      },
      /** The minimum allowed quantity. */
      min: {
        type: Number,
        default: 0,
      },
      /** The maximum allowed quantity. */
      max: {
        type: Number,
        default: 10,
      },
      /** The Tailwind screen at which the UI switches between mobile and desktop mode. */
      breakpoint: {
        type: String,
        default: 'sm',
        validator: (value) => ['2xs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(value),
      },
    },
    computed: {
      screen,
      innerValue: {
        get() {
          return this.value
        },
        set(value) {
          value = parseInt(value)
          if (!Number.isNaN(value)) {
            /** Emitted when the value changes. */
            this.$emit('change', Math.max(this.min, Math.min(this.max, value)))
          }
          // Force a re-render to prevent keeping an invalid value entered by the user
          this.$forceUpdate()
        },
      },
      decreaseButtonEnabled() {
        return this.innerValue > this.min
      },
      increaseButtonEnabled() {
        return this.innerValue < this.max
      },
      options() {
        const options = []
        for (let i = this.min; i <= this.max; i++) {
          options.push(i)
        }
        return options
      },
    },
    methods: {
      getButtonClasses(enabled) {
        return `group flex justify-center items-center w-4 h-4 flex-shrink-0 rounded-full border ${
          enabled
            ? 'text-black border-black hover:border-gray-darkest active:border-gray-darker active:bg-gray-darker transition active:transition-none duration-200'
            : 'text-gray-opacity42 border-gray-opacity42'
        }`
      },
    },
  }
</script>

<template>
  <div>
    <!-- Increment/decrement button quantity selector  -->
    <div v-if="screen[breakpoint]" class="flex items-center">
      <div class="flex-1 w-8 px-1">
        <input
          v-model.lazy="innerValue"
          type="number"
          aria-label="Quantity"
          :min="min"
          :max="max"
          class="block w-full pb-2px rounded-sm text-center"
          @keydown.enter="$event.target.blur"
        />
      </div>
      <BaseIcon
        label="Decrease quantity"
        :disabled="!decreaseButtonEnabled"
        :class="`order-first ${getButtonClasses(decreaseButtonEnabled)}`"
        @click="innerValue -= 1"
      >
        <IconMinus class="w-10px h-10px group-active:text-white fill-current" />
      </BaseIcon>
      <BaseIcon
        label="Increase quantity"
        :disabled="!increaseButtonEnabled"
        :class="getButtonClasses(increaseButtonEnabled)"
        @click="innerValue += 1"
      >
        <IconPlus class="w-10px h-10px group-active:text-white fill-current" />
      </BaseIcon>
    </div>
    <!-- Dropdown quantity selector  -->
    <div v-else>
      <select
        v-model="innerValue"
        aria-label="Quantity"
        class="z-10 absolute left-0 top-0 w-full h-full opacity-0"
      >
        <option v-for="option in options" :key="option" :value="option">{{
          option === 0 ? 'Remove' : option
        }}</option>
      </select>
      <div class="-m-1 flex items-center p-1 text-sm" aria-hidden="true">
        <span>QTY {{ innerValue }}</span>
        <BaseIcon :size="4" class="ml-2 mt-3px">
          <IconChevronDown />
        </BaseIcon>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  /* Remove arrow buttons from input */
  input[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  /* Add focus ring to invisible select by styling the next element */
  select:focus + div {
    @apply rounded-sm shadow-outline-primary-50;
  }
</style>
